import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Card, Flex, Tag, Text } from '@chakra-ui/react';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { coverageTypes } from 'utils/enums';

const CoverageDetail = ({ title, limit, startDate, endDate, children }) => {
  return (
    <AccordionItem className='w-full'>      
      <AccordionButton className='flex text-left items-center justify-between px-6 py-3 gap-4 w-full hover:bg-slate-100 border-slate-200 mobile:justify-center'>

        <Flex className='flex w-full mobile:flex-col'>
          {/* Text */}
          <Flex className='flex-col items-start min-w-[180px]'>
            <h2 className='text-lg font-medium'>{title}</h2>
          </Flex>

          {/* Limit */}
          {limit && (
            <Flex className={`mobile:flex-row mobile:gap-2`}>
              <Text>{limit[0]} {limit[1] && `or ${limit[1]}`}</Text>
            </Flex>
          )}

          {/* Period */}
          {startDate && endDate && (
            <Flex className={`mobile:flex-row mobile:gap-2`}>
              <Text>{formatDateToHumanDate(startDate)} - {formatDateToHumanDate(endDate)}</Text>
            </Flex>
          )}
        </Flex>

        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel className='border-t px-6 py-4 flex flex-col gap-6 text-base'>
        {children}
      </AccordionPanel>
    </AccordionItem>
  )
}

const CoverageCard = ({ 
  coverageType, 
  amount,
  children,
  paymentOptions,
  startDate,
  endDate
}) => {

  // Set up text for coverage details
  const coverageDetails = [
    {
      value: coverageTypes.CANCEL_ANYTIME,
      name: 'Cancel Anytime',
      description: 'Cancel your upcoming lease for any reason, any time before moving in.',
      detail: 'Get covered for costs up to 6 months in rent while your apartment is vacant, including outstanding rent, cancellation fees, and lease buyout fees where applicable.'
    },
    {
      value: coverageTypes.LEAVE_ANYTIME,
      name: 'Leave Anytime',
      description: 'Leave your current lease for any reason, any time during your lease.',
      detail: 'Get covered for costs up to 6 months in rent while your apartment is vacant, including outstanding rent, cancellation fees, and lease buyout fees where applicable.'
    }
  ]

  return (
    <Card className='rounded-lg border border-slate-200 flex flex-col divide-y divide-slate-200'>

      <Flex className={`flex-col gap-6 w-full items-end px-6 pb-8 ${amount ? 'pt-10' : 'pt-6'}`}>
        {/* Header */}
        <Flex className='w-full flex-col gap-6 sm:flex-row sm:justify-between'>
          <Flex className={`w-full flex-col pr-6 ${amount && 'sm:max-w-sm'} ${coverageType === coverageTypes.LEAVE_ANYTIME && 'mb-10'} sm:mb-0`}>          
            <Text className='text-2xl font-bold'>
              {coverageDetails.find((coverage) => coverage.value === coverageType).name}
            </Text>
            <Text>
              {coverageDetails.find((coverage) => coverage.value === coverageType).description}
            </Text>
          </Flex>

          {/* Price section */}
          {amount && (
            <Flex className={`flex-col min-w-fit gap-2 relative mt-auto sm:mb-2`}>            
              {coverageType === coverageTypes.LEAVE_ANYTIME && (
                <Tag className='absolute -top-8 w-fit flex py-1 px-4 rounded-full bg-slate-100 text-sm font-semibold uppercase tracking-wide'>
                  Starting from
                </Tag>
              )}
              <Flex className='items-end gap-2'>
                <Text className='text-3xl font-medium'>${amount?.toFixed(2)}</Text>
                <Text className='pb-1'>{coverageType === coverageTypes.LEAVE_ANYTIME ? 'per month' : 'total'}</Text>
              </Flex>
            </Flex>
          )}
        </Flex>

        <Text className='text-base'>
          {coverageDetails.find((coverage) => coverage.value === coverageType).detail}
        </Text>
      </Flex>   

      {/* Coverage Details */}
      <Flex className='flex-col gap-4'>
        <Accordion
          allowMultiple
          className='flex w-full flex-col rounded-md overflow-hidden divide-y divide-slate-200'
        >
          {startDate && endDate && (
            <CoverageDetail
              title='Coverage Period'
              startDate={startDate}
              endDate={endDate}
            >
              Your coverage period is the time frame in which you are covered for cancellations. For example, if your coverage period is between Sep 1, 2024 and Mar 1, 2025, you are eligible to cancel your lease and receive benefits during this time frame.
            </CoverageDetail>
          )}
          {children}
        </Accordion>
      </Flex>    

      {/* Payment Options */}
      {paymentOptions && (
        <Flex className='flex-col gap-4 px-6 py-8'>
          {paymentOptions}
        </Flex>    
      )} 
    </Card>
  );
};

export { CoverageCard, CoverageDetail };
