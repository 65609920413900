import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Divider, Flex, Icon, Tag, Text } from '@chakra-ui/react';
import FileUploadInput from 'common/components/file-upload-input';
import WrapInputLabel from 'components/shared/wrap-input-label';
import ApplyButtons from 'layouts/apply-buttons';
import { useUploadRequestUrlMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { useSelector } from 'react-redux';
import { selectPlansValues } from 'redux/features/plans/plansSlice';
import {
  useGetActivatePlanQuery,
  useActivatePlanMutation,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import { toast } from 'react-toastify';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { uploadFileToS3 } from 'utils/uploadS3';
import { extractErrorMsg } from 'utils/errors';
import DocumentPreview from 'common/components/document-preview';
import { documentStatusEnum } from 'utils/enums';
import { HiCheckBadge } from 'react-icons/hi2';

const PlanStepActivatePlan = ({ stepNumber, onBackClick, onNextClick, ...args }) => {
  const [leaseDoc, setLeaseDoc] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    url: '',
  });
  const {
    trackLink,
    trackPageLoad,
    trackCustomEvent,
    trackFormSubmission,
    trackFormSubmissionSuccess,
    trackFormSubmissionError,
  } = useUserTracker();
  useEffect(() => {
    trackPageLoad('activatePlanLeaseAndVerification');
  }, []);
  const [activatePlan, { isLoading: isActivateLoading }] =
    useActivatePlanMutation();
  const [idDoc, setIDDoc] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
    url: '',
  });
  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  const plansValue = useSelector(selectPlansValues);
  const [searchParams, setSearchParams] = useSearchParams();
  const searchPlanId = searchParams.get('planId');
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetActivatePlanQuery(plansValue?.id || searchPlanId, {});

  console.log(singlePlan);
  let planId = plansValue?.id || singlePlan?.id || searchPlanId;

  // Check for previous lease doc
  useEffect(() => {
    if (singlePlan?.leaseDocUrl) {
      setLeaseDoc((old) => ({
        ...old,
        isLoading: false,
        isSuccess: true,
        isError: false,
        url: singlePlan?.leaseDocUrl,
      }));
    }

    if (singlePlan?.idUrl) {
      setIDDoc((old) => ({
        ...old,
        isLoading: false,
        isSuccess: true,
        isError: false,
        url: singlePlan?.idUrl,
      }));
    }
  }, [singlePlan?.leaseDocUrl, singlePlan?.idUrl]);

  return (
    <>
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>
            Activate Plan
          </h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Verify your ID and complete your plan activation.
          </p>
        </div>
          
        {/* Buttons */}
        <ApplyButtons
          moveOn={true}
          nextTitle='Activate'
          isValid={            
            (singlePlan?.idUrl || idDoc?.url) && (singlePlan?.leaseDocUrl || leaseDoc?.url)
          }
          onNextClick={() => {
            const submitValues = {
              planId,
              leaseDocUrl: leaseDoc.url,
              idUrl: idDoc.url,
            };
            trackFormSubmission(submitValues);
            activatePlan(submitValues)
              .unwrap()
              .then((d) => {
                trackFormSubmissionSuccess(submitValues);

                if (leaseDoc.isSuccess && idDoc.isSuccess) {
                  onNextClick();
                }
              })
              .catch((e) => {
                const errMsg = extractErrorMsg(e, 'Update Failed');
                toast.error(errMsg);
                trackFormSubmissionError(submitValues, errMsg);
              });
          }}
          disableBack
        />
      </div>

      {/* main content */}
      <div className='flex flex-col h-full w-full gap-12 p-10 overflow-scroll no-scrollbar mobile:px-2 mobile:pb-32'>
        <div className='flex flex-col items-center divide-y divide-slate-300'>
          {leaseDoc?.url && idDoc?.url ? (
            <Flex className='flex-col w-full gap-7 pb-10'>
              <Flex className='flex-col gap-1 w-full items-left'>
                <h2 className='text-xl font-bold'>
                  You're Ready to Activate Your Plan
                </h2>
                <Text className='text-base'>
                  Since you've already uploaded your lease agreement and ID, you're all set to activate your plan.
                </Text>
              </Flex>
            </Flex>
          ) : (
            <Flex className='flex-col w-full gap-7 pb-10'>
              <Flex className='flex-col gap-1 w-full items-left'>
                <h2 className='text-xl font-bold'>
                  Complete Your Verification
                </h2>
                <Text className='text-base'>
                  Please confirm a few details to activate your plan. It'll only take you five minutes.
                </Text>
              </Flex>
            </Flex>
          )}

          {/* Lease Document is conditional on not being uploaded previously */}
          <Flex className='flex-col w-full gap-7 py-10'>
            {/* title */}
            <Flex className='flex-col gap-1 w-full items-left'>
              <h2 className='text-xl font-bold'>
                Verify Your Lease Agreement
                {singlePlan?.leaseDocUrl?.url && (
                  <Tag className='ml-4 px-3 gap-2 rounded-full' colorScheme='green'>
                    <Icon as={HiCheckBadge} />
                    Uploaded
                  </Tag>
                )}
              </h2>
              <Text className='text-base'>
                To complete your verification, simply upload a PDF copy of your full lease agreement. Make sure all pages are included in the file.
              </Text>
            </Flex>
            
            {!singlePlan?.leaseDocUrl?.url && (
              <div className='w-full flex flex-col gap-2'>
                <WrapInputLabel isRequired>                  
                  Upload Your Current Lease Agreement
                </WrapInputLabel>

              {/* file upload input */}
              <div className='w-full flex flex-col items-stretch'>
                <FileUploadInput
                  disableUpload={leaseDoc.isLoading}
                  isUploadError={leaseDoc.isError}
                  isUploadLoading={leaseDoc.isLoading}
                  isUploadSuccess={leaseDoc.isSuccess}
                  handlePDFClear={() => {
                    setLeaseDoc((old) => ({
                      ...old,
                      isLoading: false,
                      isSuccess: false,
                      isError: false,
                      url: '',
                    }));
                  }}
                  handlePDFUpload={async (file) => {
                    let uploadUrlReq;
                    let fileTrack = { fileName: file.name, size: file.size };
                    setLeaseDoc((old) => ({
                      ...old,
                      isLoading: true,
                      fileName: file.name,
                    }));

                    // Get the signing url for uploading to s3
                    try {
                      trackCustomEvent('fileUploadSelection', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                      });
                      trackCustomEvent('s3UrlUploadRequest', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                      });
                      uploadUrlReq = await uploadRequestUrl({
                        fileName: file.name,
                        contentType: file.type,
                      }).unwrap();
                    } catch (e) {
                      setLeaseDoc((old) => ({
                        ...old,
                        isLoading: false,
                        isError: true,
                        fileName: file.name,
                      }));
                      const errMsg = extractErrorMsg(
                        e,
                        'failed to upload lease document'
                      );

                      toast.error(errMsg);
                      trackCustomEvent('s3UrlUploadRequestError', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        error: errMsg,
                      });
                      return;
                    }

                    if (
                      !uploadUrlReq?.data?.uploadUrl ||
                      !uploadUrlReq?.data?.viewUrl
                    ) {
                      setLeaseDoc((old) => ({
                        ...old,
                        isLoading: false,
                        isError: true,
                        fileName: file.name,
                      }));
                      toast.error('failed to upload lease document');
                      trackCustomEvent('s3UrlUploadRequestError', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        error: 'Improper s3 url return value',
                      });
                    }

                    // Upload the file to s3
                    try {
                      trackCustomEvent('s3FileUploadRequest', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });
                      await uploadFileToS3({
                        s3Url: uploadUrlReq?.data?.uploadUrl,
                        file,
                      });

                      trackCustomEvent('s3FileUploadSuccess', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });

                      // Have to set the uploaded file url on the lease document
                      setLeaseDoc((old) => ({
                        ...old,
                        url: uploadUrlReq?.data?.viewUrl,
                        fileName: file.name,
                        isLoading: false,
                        isError: false,
                        isSuccess: true,
                      }));

                      toast.info(
                        uploadUrlReq?.status ||
                          'lease has been uploaded successfully'
                      );
                    } catch (e) {
                      setLeaseDoc((old) => ({
                        ...old,
                        fileName: file.name,
                        isLoading: false,
                        isError: true,
                        isSuccess: false,
                      }));
                      const errMsg = extractErrorMsg(
                        e,
                        'failed to upload lease document'
                      );
                      toast.error(errMsg);
                      trackCustomEvent('s3FileUploadError', {
                        name: 'leaseDocUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                        error: errMsg,
                      });
                      return;
                    }
                  }}
                />
              </div>

              {leaseDoc?.url && (
                <div className='w-full flex flex-col items-center gap-2 mt-4'>
                  <WrapInputLabel isRequired>                  
                  View Uploaded Lease Agreement
                </WrapInputLabel>
                  {/* uploaded file */}
                  <DocumentPreview
                    url={leaseDoc?.url}
                    label={leaseDoc.fileName}
                  />
                </div>
              )}
              </div>
            )}
          </Flex>        

          {/* Identity Verification */}
          <Flex className='flex-col w-full gap-7 pt-10'>
            {/* title */}
            <Flex className='flex-col gap-1 w-full items-left'>
              <h2 className='text-xl font-bold'>
                Verify Your Identity
                {singlePlan?.idUrl && (
                  <Tag className='ml-4 px-3 gap-2 rounded-full' colorScheme='green'>
                    <Icon as={HiCheckBadge} />
                    Uploaded
                  </Tag>
                )}
              </h2>
              <Text className='text-base'>
                For identify verification, please upload a government-issued ID, such as a driver’s license, passport, or citizenship card. Ensure the ID is clear and all details are visible.
              </Text>
            </Flex>
            
            {!singlePlan?.idUrl && (
            <div className='w-full flex flex-col gap-2'>
              <WrapInputLabel isRequired>                  
                  Upload Your ID
                </WrapInputLabel>

              {/* file upload input */}
              <div className='w-full flex flex-col items-stretch'>
                <FileUploadInput
                  disableUpload={idDoc.isLoading}
                  isUploadError={idDoc.isError}
                  isUploadLoading={idDoc.isLoading}
                  isUploadSuccess={idDoc.isSuccess}
                  handlePDFClear={() => {
                    setIDDoc((old) => ({
                      ...old,
                      isLoading: false,
                      isSuccess: false,
                      isError: false,
                      url: '',
                    }));
                  }}
                  handlePDFUpload={async (file) => {
                    let uploadUrlReq;
                    setIDDoc((old) => ({ ...old, isLoading: true }));
                    let fileTrack = { fileName: file.name, size: file.size };

                    // Get the signing url for uploading to s3
                    try {
                      trackCustomEvent('fileUploadSelection', {
                        name: 'idUrl',
                        file: fileTrack,
                      });
                      trackCustomEvent('s3UrlUploadRequest', {
                        name: 'idUrl',
                        file: fileTrack,
                      });
                      uploadUrlReq = await uploadRequestUrl({
                        fileName: file.name,
                        contentType: file.type,
                      }).unwrap();
                    } catch (e) {
                      const errMsg =
                        e?.msg ||
                        e?.data?.msg ||
                        e?.message ||
                        'failed to upload lease document';
                      setIDDoc((old) => ({
                        ...old,
                        isLoading: false,
                        fileName: file.name,
                        isError: true,
                      }));
                      toast.error(errMsg);
                      trackCustomEvent('s3UrlUploadRequestError', {
                        name: 'idUrl',
                        file: fileTrack,
                        error: errMsg,
                      });
                      return;
                    }

                    if (
                      !uploadUrlReq?.data?.uploadUrl ||
                      !uploadUrlReq?.data?.viewUrl
                    ) {
                      setIDDoc((old) => ({
                        ...old,
                        isLoading: false,
                        fileName: file.name,
                        isError: true,
                      }));
                      toast.error('failed to upload id document');
                    }

                    // Upload the file to s3

                    try {
                      trackCustomEvent('s3FileUploadRequest', {
                        name: 'idUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });
                      await uploadFileToS3({
                        s3Url: uploadUrlReq?.data?.uploadUrl,
                        file,
                      });
                      trackCustomEvent('s3FileUploadSuccess', {
                        name: 'idUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                      });

                      // Have to set the uploaded file url on the id document
                      setIDDoc((old) => ({
                        ...old,
                        url: uploadUrlReq?.data?.viewUrl,
                        fileName: file.name,
                        isLoading: false,
                        isError: false,
                        isSuccess: true,
                      }));

                      toast.info(
                        uploadUrlReq?.status ||
                          'id has been uploaded successfully'
                      );
                    } catch (e) {
                      setIDDoc((old) => ({
                        ...old,
                        fileName: file.name,
                        isLoading: false,
                        isError: true,
                        isSuccess: false,
                      }));
                      const errMsg = extractErrorMsg(
                        e,
                        'failed to upload id document'
                      );
                      toast.error(errMsg);
                      trackCustomEvent('s3FileUploadError', {
                        name: 'idUrl',
                        file: fileTrack,
                        s3Url: uploadUrlReq?.data,
                        error: errMsg,
                      });
                      return;
                    }
                  }}
                />
              </div>

              {idDoc?.url && (
                <div className='w-full flex flex-col items-center gap-2 mt-4'>
                  <WrapInputLabel isRequired>                  
                  View Uploaded ID
                </WrapInputLabel>
                  {/* uploaded file */}
                  <DocumentPreview
                    url={idDoc?.url}
                    label={idDoc.fileName}
                  />
                </div>
              )}
            </div>
            )}
          </Flex>
        </div>
      </div>
    </>
  );
};

export default PlanStepActivatePlan;
