import { FaCheck, FaCircleCheck } from 'react-icons/fa6';
import { IoMdInformationCircle } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useConfirmAClaimMutation,
  useGetSingleClaimQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import Button from 'common/components/button';
import { Flex, Text } from '@chakra-ui/react';
import ClaimsDetailInfo from 'components/claims/claims-detail-info';
import ClaimsDetailAction from 'components/claims/claims-detail-action';
import ApplyButtons from 'layouts/apply-buttons';

const StartClaimApprove = ({ isApprovedClaim, setIsApprovedClaim }) => {
  // HOOKS
  const [confirmClaim, { isLoading: confirmClaimLoading }] =
    useConfirmAClaimMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const claimId = searchParams.get('claimID');
  const { data: values, isLoading: getSingleClaimLoading } =
    useGetSingleClaimQuery(claimId);

  console.log('values', values);

  // HANDLERS
  const handleGoToDashboard = () => {
    navigate(`/dashboard/claims/${claimId}`);
  };

  if (isApprovedClaim) {
    return (
      <>
        <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
          <div className='flex flex-col gap-2 w-full mobile:gap-1'>
            <h1 className='text-3xl mobile:text-2xl font-bold'>
              Claim Finalized
            </h1>
            <p className='font-medium text-xl mobile:text-lg'>
              Your claim has been successfully processed and is now complete.
            </p>
          </div>

          {/* button */}
          <ApplyButtons
            moveOn={true}
            onNextClick={handleGoToDashboard}
            nextTitle='Back to Claim'
            isValid={true}
            disableBack
          />
        </div>
        <div className='p-10 overflow-scroll no-scrollbar w-full h-full flex flex-col justify-between gap-8'>
          {/* Approved claim details card */}
          <ClaimsDetailInfo claim={values} />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
          <div className='flex flex-col gap-2 w-full mobile:gap-1'>
            <h1 className='text-3xl mobile:text-2xl font-bold'>
              Finalize Claim
            </h1>
            <p className='font-medium text-xl mobile:text-lg'>
              Please review and confirm the details to finalize your claim.
            </p>
          </div>

          {/* button */}
          <ApplyButtons
            moveOn={true}
            isValid={true}
            isLoading={confirmClaimLoading}
            nextTitle='Confirm Claim'
            onNextClick={() => {
              confirmClaim(claimId)
                .unwrap()
                .then(() => {
                  setIsApprovedClaim(true);
                  toast.info(`Claim confirmed successfully`, {
                    toastId: 'confirmClaimSuccess1',
                  });
                })
                .catch((error) => {
                  console.error(error);
                  handleGoToDashboard();
                  toast.error(error?.message || `Couldn't confirm the claim`, {
                    toastId: 'confirmClaimError1',
                  });
                });
            }}
            onBackClick={handleGoToDashboard}
          />
        </div>
        <div className='p-10 overflow-scroll no-scrollbar w-full h-full flex flex-col justify-between gap-8'>
          {/* confirmation disclaimer */}
          <Flex className='flex-col gap-2 w-full'>
            <Text>
              By confirming this claim, you agree that payment for the
              deductible, as stated above, will be debited from your payment
              method on file.
            </Text>
            <Text>
              Once confirmed, your approved move-out date, as
              stated above, will been finalized with your property manager or
              landlord, and you will be required to vacate the apartment before
              this date. Once your claim is confirmed, it will be final and
              cannot be modified or changed.
            </Text>
          </Flex>
          {/* Approved claim details card */}
          <ClaimsDetailAction claim={values} showButton={false} />          
        </div>
      </>
    );
  }
};

export default StartClaimApprove;
