import { IoIosArrowBack } from 'react-icons/io';
import {
  Flex,
  Text,
  Card,
  Divider,
  HStack,
  Skeleton,
  Icon,
  useClipboard,
  Heading,
} from '@chakra-ui/react';
import { RiArrowDownSFill } from 'react-icons/ri';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { Link, useParams } from 'react-router-dom';
import DocumentPreview from 'common/components/document-preview';
import EmptyData from 'components/shared/empty-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import StatusLabel from 'components/shared/status-label';
import DashboardMainLayout from 'layouts/dashboard-main';
import { useGetSinglePlanQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import DownloadPdfButton from 'components/plans/download-button';
import PaymentSummary from 'components/plans/payment-summary';
import { FaCopy } from 'react-icons/fa6';
import ClipboardButton from 'components/shared/clipboard-button';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import Button from 'common/components/button';
import PlanInfoCard from 'components/plans/plan-info-card';
import { useEffect } from 'react';
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { useDispatch } from 'react-redux';
import { setUserDetails } from 'redux/features/auth/authSlice';
import { DisclosureCard } from 'components/coverages/disclosure-card';
import DetailCard from 'components/coverages/detail-card';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import { coverageTypes, planPaymentMode } from 'utils/enums';
import ContactCard from 'components/shared/contact-card';
import { FaChevronRight } from 'react-icons/fa6';
import { CoverageCard, CoverageDetail } from 'components/coverages/coverage-card';
import generalLimits from 'components/coverages/general-limits';

const PlanDetails = () => {
  // HOOKS
  const { planId } = useParams();
  const dispatch = useDispatch();
  const { data: singlePlan, isLoading: getSinglePlanLoading } =
    useGetSinglePlanQuery(planId);
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // DATA INITIALIZATION
  const hasCancelAnytime = singlePlan?.coverage?.cancelAnytime?.payment?.stripe?.id;
  const hasLeaveAnytime = singlePlan?.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id || singlePlan?.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id;
  const hasCoverage = hasCancelAnytime || hasLeaveAnytime;


  console.log('Plan details', singlePlan);

  return (
    <DashboardMainLayout title={`Plan Details`}>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Home
          </Link>
          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
          <Link
            to='/dashboard/plans'
            className='text-[#CED0CE] text-base font-normal'
          >
            Plans{' '}
          </Link>
          <Icon as={FaChevronRight} boxSize={3} className='text-[#CED0CE]'/>
          <Link to='' className='text-[#191923] text-base font-normal'>
            {singlePlan?.id}
          </Link>
        </div>
        {/* mobile: back button */}
        {/* <Link
          to='/dashboard/plans'
          className='text-[#191923] text-sm font-medium hidden mobile:inline-flex items-center gap-2'
        >
          <IoIosArrowBack className='text-xl text-[#9CA3AF]' />
          Back
        </Link> */}

        {/* buttons */}
        <div className='flex w-full gap-4 mobile:flex-col'>
          <Link className='w-full' to='/dashboard/apply-for-claims'>
            <Button title='Submit Claim' />
          </Link>
          <AnchorLinkTag
            className='w-full'
            hrefPath='mailto:members@releaserent.com'
          >
            <Button theme='secondary' title='Chat with an Expert' />
          </AnchorLinkTag>
        </div>

        {/* plan card and payment summary */}
        {getSinglePlanLoading ? (
          <LoadingSpinner />
        ) : isEmpty(singlePlan) ? (
          <EmptyData />
        ) : (
          <div className='w-full gap-5 mobile:bg-white'>
            <Flex className='w-full flex-col gap-10'>
              {/* plan card and info */}
              <Flex className='flex-col gap-6 mt-6'>
                {/* plan heading and status */}
                <Flex className='flex-col gap-4 w-full'>
                  <Flex className='gap-4 items-center justify-between'>
                    <h2 className='text-2xl font-bold'>Your Plan Details</h2>
                    <StatusLabel status={singlePlan.status} size='sm' />
                  </Flex>
                </Flex>

                {/* plan info card */}
                <Flex className='flex-col gap-6 w-full mb-6'>
                  <PlanInfoCard
                    plan={singlePlan}
                    member={userDetails}
                  />

                  <Flex className='flex-col gap-4'>
                    {singlePlan?.id && (
                      <DownloadPdfButton planId={singlePlan?.id} />
                    )}
                    <AnchorLinkTag
                      className='w-full'
                      hrefPath='https://www.releaserent.com/legal/release-leave-anytime-terms-conditions'
                    >
                      <Button
                        theme='secondary'
                        title='View Product Terms & Conditions'
                      />
                    </AnchorLinkTag>
                  </Flex>
                </Flex>

                <PaymentSummaryCard
                  plan={singlePlan}
                  complete={true}
                />
              </Flex>              

              {/* plan coverages section */}
              {hasCoverage && (
                <Flex className='flex-col gap-6 mt-6'>
                  {/* coverages heading */}
                  <Flex className='flex-col gap-1 w-full items-left'>
                    <h2 className='text-2xl font-bold'>Plan Coverages</h2>
                    <Text>
                      Your plan includes the following coverages, limits,
                      deductibles, and exclusions.
                    </Text>
                  </Flex>

                  {/* coverages */}
                  {hasCancelAnytime && (
                    <CoverageCard
                      coverageType={coverageTypes.CANCEL_ANYTIME}
                      startDate={singlePlan.coverage.cancelAnytime.startDate}
                      endDate={singlePlan.coverage.cancelAnytime.endDate}
                    >
                      {generalLimits}
                    </CoverageCard>
                  )}
                  {hasLeaveAnytime && (
                    <CoverageCard 
                      coverageType={coverageTypes.LEAVE_ANYTIME}
                      startDate={singlePlan.coverage.leaveAnytime.startDate}
                      endDate={singlePlan.coverage.leaveAnytime.endDate}
                    >
                      {generalLimits}

                      {/* Elimination Period / Minimum Required Stay */}
                      <CoverageDetail
                        title='Required Stay'
                        limit={['one month', '1/12 of the lease term']}
                      >
                        The required stay is the minimum amount of time you must wait once your coverage period begins before you are eligible to leave early and receive benefits. For example, if your required stay is one month, you must wait one month after your coverage period begins before you are eligible to leave early and receive benefits.
                      </CoverageDetail>
                    </CoverageCard>
                  )}
                </Flex>
              )}

              {/* Contact Us Card */}
              <ContactCard
                title='Contact Us'                
              >
                Questions about your plan or with claims?. Contact us by email, phone, or text and we can help you answer any questions you may have about purchasing a plan, making claims, and more.
              </ContactCard>
            </Flex>
          </div>
        )}
      </div>
    </DashboardMainLayout>
  );
};

export default PlanDetails;
