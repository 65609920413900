import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import StatusLabel from 'components/shared/status-label';
import { Icon } from '@chakra-ui/react';
import { claimTypeEnum } from 'common/utils/enums';

const ClaimsTable = ({ claims }) => {
  // DATA INTIAILIZATION
  const navigate = useNavigate();

  return (
    <table className='flex flex-col w-full'>

      {/* body */}
      <tbody className='w-full rounded-lg border border-gray-200 divide-y'>
        {claims.map((claim, index) => (
          <tr
            key={index}
            onClick={() => navigate(`/dashboard/claims/${claim.id}`)}
            className='grid grid-cols-[1fr_auto_auto_auto_160px] gap-4 w-full hover:bg-gray-50 px-3 py-2 cursor-pointer mobile:flex mobile:flex-col mobile:gap-0'
          >
            {/* Claim ID */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
              <span className='uppercase tracking-wider font-bold text-sm' >
                Claim Number
              </span>
              <span className='uppercase tracking-wider text-lg'>
                {claim?.id}
              </span>
            </td>

            {/* Submited On */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
              <span className='uppercase tracking-wider font-bold text-sm' >
                Submitted On
              </span>
              <span className='uppercase tracking-wider text-lg'>
              {formatDateToHumanDate(claim.submissionAt)}
              </span>
            </td>

            {/* Claim Type */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
              <span className='uppercase tracking-wider font-bold text-sm' >
                Claim Type
              </span>
              <span className='uppercase tracking-wider text-lg'>
              {claim?.claimType === claimTypeEnum.NEW_JOB
                      ? 'New Job'
                      : claim?.claimType === claimTypeEnum.PURCHASED_HOME
                      ? 'Purchased Home'
                      : 'Other Claim'}
              </span>
            </td>

            {/* Move out date */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
            <span className='uppercase tracking-wider font-bold text-sm' >
                Cancellation Date
              </span>
              <span className='uppercase tracking-wider text-lg'>
              {formatDateToHumanDate(claim.moveOutDate)}
              </span>
              
            </td>

            {/* Status */}
            <td className='w-full p-3 flex items-center'>
              <div className='gap-2 inline-flex justify-between w-full items-center'>
                <StatusLabel status={claim.status} size='sm' />
                <Icon as={MdKeyboardArrowRight} boxSize={4} className='mobile:hidden'/>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ClaimsTable;
