import { AddressAutofill } from '@mapbox/search-js-react';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApplyButtons from 'layouts/apply-buttons';
import { useUploadDocumentMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { selectClaimsDraftValue } from 'redux/features/claims/claimsSlice';
import { isEmpty } from 'common/utils/isEmpty';
import FileUploadInput from 'common/components/file-upload-input';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';

const StartClaimHomePurchase = ({
  onBack,
  onNext,
  values,
  isValid,
  handleChange,
  setFieldValue,
  handleBlur,
  saveDraft,
  setFieldTouched,
}) => {
  // STATES
  const [disableFileUpload, setDisableFileUpload] = useState(false);

  // HOOKS
  const draftedClaimsValue = useSelector(selectClaimsDraftValue);
  const [
    uploadSinglePDF,
    {
      isLoading: isUploadLoading,
      isSuccess: isUploadSuccess,
      isError: isUploadError,
    },
  ] = useUploadDocumentMutation();

  return (
    <>
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>Claim Details</h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Provide more details about your claim and cancellation.
          </p>
        </div>

        {/* button */}
        <ApplyButtons
          moveOn={true}
          isValid={isValid}
          onNextClick={() => {
            onNext();
            saveDraft(values);
          }}
          onBackClick={() => {
            onBack();
            saveDraft(values);
          }}
        />
      </div>
      <div className='p-10 overflow-scroll no-scrollbar w-full h-full flex flex-col justify-between gap-8'>
        {/* inputs */}
        <form
          onSubmit={(e) => e.preventDefault()}
          className='flex flex-col gap-6 w-full'
        >
          {/* Street Address 1 */}
          <div className='flex flex-col gap-2'>
            <WrapInputLabel isRequired>Street Address 1</WrapInputLabel>
            <AddressAutofill
              accessToken={`${process.env.REACT_APP_MAPBOX_TOKEN}`}
            >
              <Input
                autoComplete='street-address'
                placeholderText='Start typing to search for an address'
                value={values.purchasedHome.homeAddress.streetAddress1}
                onChange={({ target: { value } }) => {
                  setFieldValue(
                    'purchasedHome.homeAddress.streetAddress1',
                    value
                  );
                }}
                onBlur={handleBlur}
                name='purchasedHome.homeAddress.streetAddress1'
              />
            </AddressAutofill>
            <FormError name='purchasedHome.homeAddress.streetAddress1' />
          </div>

          {/* Street Address 2 */}
          <div className='flex flex-col gap-2'>
            <WrapInputLabel isRequired={false}>Street Address 2</WrapInputLabel>
            <Input
              // autoComplete='address-level3'
              placeholderText='Specific address (e.g. Apt, Suite, Bldg) (if applicable)'
              value={values.purchasedHome.homeAddress.streetAddress2}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  'purchasedHome.homeAddress.streetAddress2',
                  value
                );
              }}
              onBlur={handleBlur}
              name='purchasedHome.homeAddress.streetAddress2'
            />
            <FormError name='purchasedHome.homeAddress.streetAddress2' />
          </div>

          {/* City */}
          <div className='flex flex-col gap-2'>
            <WrapInputLabel isRequired>City</WrapInputLabel>
            <Input
              autoComplete='address-level2'
              placeholderText='Your City'
              value={values.purchasedHome.homeAddress.city}
              onChange={({ target: { value } }) => {
                setFieldValue('purchasedHome.homeAddress.city', value);
              }}
              onBlur={handleBlur}
              name='purchasedHome.homeAddress.city'
            />
            <FormError name='purchasedHome.homeAddress.city' />
          </div>

          {/* State */}
          <div className='flex flex-col gap-2'>
            <WrapInputLabel isRequired>State</WrapInputLabel>
            <Input
              autoComplete='address-level1'
              placeholderText='Your State'
              value={values.purchasedHome.homeAddress.state}
              onChange={({ target: { value } }) => {
                setFieldValue('purchasedHome.homeAddress.state', value);
              }}
              onBlur={handleBlur}
              name='purchasedHome.homeAddress.state'
            />
            <FormError name='purchasedHome.homeAddress.state' />
          </div>

          {/* Country */}
          <div className='flex flex-col gap-2'>
            <WrapInputLabel isRequired>Country</WrapInputLabel>
            <Input
              autoComplete='country-name'
              placeholderText='Your Country'
              value={values.purchasedHome.homeAddress.country}
              onChange={({ target: { value } }) => {
                setFieldValue('purchasedHome.homeAddress.country', value);
              }}
              onBlur={handleBlur}
              name='purchasedHome.homeAddress.country'
            />
            <FormError name='purchasedHome.homeAddress.country' />
          </div>

          {/* Postal Code */}
          <div className='flex flex-col gap-2'>
            <WrapInputLabel isRequired>Zip Code</WrapInputLabel>
            <Input
              autoComplete='postal-code'
              placeholderText='Your Zip Code'
              value={values.purchasedHome.homeAddress.postalCode}
              onChange={({ target: { value } }) => {
                setFieldValue('purchasedHome.homeAddress.postalCode', value);
              }}
              onBlur={handleBlur}
              name='purchasedHome.homeAddress.postalCode'
            />
            <FormError name='purchasedHome.homeAddress.postalCode' />
          </div>

          {/* date */}
          <div className='flex gap-3 items-start w-full pt-2 mobile:flex-col mobile:gap-6'>
            {/* start date */}
            <div className='flex flex-col gap-2 w-full'>
              <WrapInputLabel isRequired>Offer Date</WrapInputLabel>

              <Input
                placeholderText='Select date'
                type='date'
                value={values.purchasedHome.offerDate}
                onChange={(e) => {
                  setFieldTouched('purchasedHome.offerDate');
                  handleChange(e);
                }}
                onBlur={handleBlur}
                name='purchasedHome.offerDate'
              />
              <FormError name='purchasedHome.offerDate' />
            </div>

            {/* end date */}
            <div className='flex flex-col gap-2 w-full'>
              <WrapInputLabel isRequired>
                Expected Possession Date
              </WrapInputLabel>

              <Input
                placeholderText='Select date'
                type='date'
                value={values.purchasedHome.proposedStart}
                onChange={(e) => {
                  setFieldTouched('purchasedHome.proposedStart');
                  handleChange(e);
                }}
                onBlur={handleBlur}
                name='purchasedHome.proposedStart'
              />
              <FormError name='purchasedHome.proposedStart' />
            </div>
          </div>

          {/* Upload a copy of your offer of purchase */}
          <div className='flex flex-col gap-2'>
            <WrapInputLabel isRequired>Dated Offer of Purchase</WrapInputLabel>
            <FileUploadInput
              disableUpload={disableFileUpload}
              isUploadError={isUploadError}
              isUploadLoading={isUploadLoading}
              isUploadSuccess={
                isUploadSuccess ||
                !isEmpty(draftedClaimsValue?.purchasedHome?.offerPdfUrl)
              }
              handlePDFClear={() => {
                setFieldValue('purchasedHome.offerPdfUrl', '');
              }}
              handlePDFUpload={(file) => {
                uploadSinglePDF({
                  docFile: file,
                })
                  .unwrap()
                  .then((data) => {
                    setDisableFileUpload(true);
                    setFieldValue('purchasedHome.offerPdfUrl', data?.data);
                    toast.info(data?.status || 'Success');
                  })
                  .catch((error) => {
                    toast.error(error?.msg || 'failed to upload document');
                  });
              }}
            />
            <FormError name='purchasedHome.offerPdfUrl' />
          </div>
        </form>
      </div>
    </>
  );
};

export default StartClaimHomePurchase;
