import { useEffect, useState } from 'react';
import {
  FaBuilding,
  FaCreditCard,
  FaIdCard,
  FaListCheck,
  FaUser,
} from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ProgressCard from 'components/shared/progress-card';
import CancelApplicationModal from 'components/shared/cancel-application-modal';
import ApplyForPlanLayout from 'layouts/apply-for-plan';
import ModalLayout from 'layouts/modal';
import {
  clearPlansDraft,
  selectPlanID,
  selectPlansStep,
} from 'redux/features/plans/plansSlice';
import { FaFileAlt, FaShieldAlt } from 'react-icons/fa';
import { ApplyAside, ApplyHeader, ApplyContainer, ApplyMain } from 'components/shared/apply-form';

const ApplyForPlan = () => {
  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const draftPlanStep = useSelector(selectPlansStep);
  const [searchParams] = useSearchParams();
  const paramsStep = searchParams.get('step');
  const draftPlanID = useSelector(selectPlanID);

  // STATES
  const [step, setStep] = useState(0);
  const [cancelModalState, setCancelModalState] = useState(false);

  // HANDLERS
  const toggleCancelModal = () => {
    setCancelModalState(!cancelModalState);
  };

  // SIDE EFFECTS
  useEffect(() => {
    setStep(+paramsStep || draftPlanStep);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <>
      <ApplyContainer>

        {/* Header */}
        <ApplyHeader 
          onClick={() => {
            navigate('/dashboard/plans');
            dispatch(clearPlansDraft());
          }} 
        />
        
        {/* Main Content */}
        <ApplyMain>
          
          {/* aside */}
          <ApplyAside
            // Save text is different depending on whether the plan has been created, and whether the user is on the last step
            save={() => {
              navigate('/dashboard/plans');
              dispatch(clearPlansDraft());
            }}
            saveText={
              step > 5 ? 'Go to Home'
              : step === 5 ? 'Activate Plan Later'
              : draftPlanID ? 'Save & Continue Later'
              : 'Cancel Application'
            }
            // Cancel is only available once draftPlanID is set. It is also disabled after payment has been set (step 4)
            cancel={toggleCancelModal}
            cancelCondition={
              draftPlanID && step < 5
            }
          >                        
            <ProgressCard
              CardIcon={FaBuilding}
              title='Select Property'
              step={1}
              isBlurred={step < 0}
              isCompleted={step > 0}
            />
            <ProgressCard
              CardIcon={FaUser}
              title='Personal Info'
              step={2}
              isBlurred={step < 1}
              isCompleted={step > 1}
            />
            <ProgressCard
              CardIcon={FaFileAlt}
              title='Lease Details'
              step={3}
              isBlurred={step < 2}
              isCompleted={step > 2}
            />
            <ProgressCard
              CardIcon={FaShieldAlt}
              title='Choose Coverage'
              step={4}
              isBlurred={step < 3}
              isCompleted={step > 3}
            />
            <ProgressCard
              CardIcon={FaCreditCard}
              title='Set Payment'
              step={5}
              isBlurred={step < 4}
              isCompleted={step > 4}
            />
            <ProgressCard
              CardIcon={FaIdCard}
              title='Activate Plan'
              step={6}
              isBlurred={step < 5}
              isCompleted={step > 5}
            />
          </ApplyAside>
            
          <ApplyForPlanLayout
            stepNumber={step}
            setStepNumber={setStep}
          />
        </ApplyMain>
      </ApplyContainer>

      {/* Cancel Modal */}
      <ModalLayout
        toggleModal={toggleCancelModal}
        modalIsOpen={cancelModalState}
      >
        <CancelApplicationModal
          planId={draftPlanID}
          closeModal={toggleCancelModal}
        />
      </ModalLayout>

    </>
  );
};

export default ApplyForPlan;
