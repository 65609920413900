import { Card, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const ClaimsInfoLayout = ({ children, title = 'Title' }) => {
  return (
    <Card className='flex flex-col gap-8 w-full px-6 py-8 rounded-lg border border-slate-200'>
      <h2 className='text-2xl font-bold'>{title}</h2>
      <Flex className='flex-col w-full gap-6'>
        {children}
      </Flex>
    </Card>
  );
};

export default ClaimsInfoLayout;
