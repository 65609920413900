/* eslint-disable no-unused-vars */
import WrapInputLabel from 'components/shared/wrap-input-label';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApplyButtons from 'layouts/apply-buttons';
import { useUploadDocumentMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { selectClaimsDraftValue } from 'redux/features/claims/claimsSlice';
import { isEmpty } from 'common/utils/isEmpty';
import FileUploadInput from 'common/components/file-upload-input';
import FormError from 'components/shared/form-error';
import TextAreaInput from 'components/shared/text-area-input';

const StartClaimOther = ({
  onBack,
  onNext,
  values,
  isValid,
  handleChange,
  setFieldValue,
  handleBlur,
  saveDraft,
  setFieldTouched,
}) => {
  // STATES
  const [disableFileUpload, setDisableFileUpload] = useState(false);

  // HOOKS
  const draftedClaimsValue = useSelector(selectClaimsDraftValue);
  const [
    uploadSinglePDF,
    {
      isLoading: isUploadLoading,
      isSuccess: isUploadSuccess,
      isError: isUploadError,
    },
  ] = useUploadDocumentMutation();

  return (
    <>
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>Claim Details</h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Provide more details about your claim and cancellation.
          </p>
        </div>

        {/* button */}
        <ApplyButtons
          moveOn={true}
          isValid={isValid}
          onNextClick={() => {
            onNext();
            saveDraft(values);
          }}
          onBackClick={() => {
            onBack();
            saveDraft(values);
          }}
        />
      </div>
      <div className='p-10 overflow-scroll no-scrollbar w-full h-full flex flex-col justify-between gap-8'>
        {/* inputs */}
        <div className='flex flex-col gap-6 w-full'>
          {/* Description: */}
          <div className='flex flex-col gap-2 w-full'>
            <WrapInputLabel isRequired>Cancellation Reason</WrapInputLabel>

            <TextAreaInput
              placeholderText='Describe why you are cancelling your lease'
              value={values?.otherClaim?.description}
              onChange={(e) => {
                setFieldTouched('otherClaim.description');
                handleChange(e);
              }}
              onBlur={handleBlur}
              name='otherClaim.description'
            />
            <FormError name='otherClaim.description' />
          </div>

          {/* Upload any supporting documents: */}
          <div className='flex flex-col gap-2'>
            <WrapInputLabel isRequired>Supporting Documents</WrapInputLabel>
            <FileUploadInput
              disableUpload={disableFileUpload}
              isUploadError={isUploadError}
              isUploadLoading={isUploadLoading}
              isUploadSuccess={
                isUploadSuccess ||
                !isEmpty(draftedClaimsValue?.otherClaim?.supportingDocUrl)
              }
              handlePDFClear={() => {
                setFieldValue('otherClaim.supportingDocUrl', '');
              }}
              handlePDFUpload={(file) => {
                uploadSinglePDF({
                  docFile: file,
                })
                  .unwrap()
                  .then((data) => {
                    setDisableFileUpload(true);
                    setFieldValue('otherClaim.supportingDocUrl', data?.data);
                    toast.info(data?.status || 'Success');
                  })
                  .catch((error) => {
                    toast.error(error?.msg || 'failed to upload document');
                  });
              }}
            />
            <FormError name='otherClaim.supportingDocUrl' />
          </div>
        </div>
      </div>
    </>
  );
};

export default StartClaimOther;
