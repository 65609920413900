/* eslint-disable no-unused-vars */
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { statusEnum } from 'utils/enums';
import {
  formatDateToHumanDate,
  formatDateToHumanTime,
} from 'common/utils/formDateToHumanTime';
import StatusLabel from 'components/shared/status-label';
import { Icon } from '@chakra-ui/react';
import { useFindPropertiesQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';

const PlansTable = ({ plans }) => {
  // DATA INTIAILIZATION
  const navigate = useNavigate();
  const excludedStatuses = ['cancelled', 'complete', 'active'];

  // HANDLERS
  const getNavURL = (plan, id) => {

    // Figure out which step to navigate to based on conditions we know about the plan
    let navigationUrl = '';
    let step = 0;
    const status = plan?.status;

    // Check if the plan is complete, rejected, or cancelled
    // These should not be clickable
    const isComplete = status === statusEnum.complete ||
    status === statusEnum.rejected ||
    status === statusEnum.cancelled

    // Check if the plan is active
    // These should navigate to the plan details page
    const isActive = status === statusEnum.active

    // Check if the plan is in review
    // These should navigate to the last step (7)
    const inReview = status === statusEnum.review || status === statusEnum.inReview

    // Check if the plan has a payment method
    // These should navigate to the post-payment confirmation page
    const hasPaymentMethod = plan.coverage?.cancelAnytime?.payment?.stripe?.id || plan.coverage?.leaveAnytime?.payment?.onetime?.stripe?.id || plan.coverage?.leaveAnytime?.payment?.subscription?.stripe?.id

    // Check if the plan has the minimum required information to select coverages
    // These should navigate to the coverage selection page
    const hasLease = plan?.lease?.address && plan?.lease?.startDate && plan?.lease?.endDate && plan?.lease?.signDate && plan?.lease?.monthlyRent

    // Check if the plan has a property selected
    // These should navigate to the personal info page, otherwise they should navigate to the property selection page
    const hasProperty = plan?.lease?.property !== ''
    
    // Set the navigation URL and inferred step number based on the conditions above
    if (isComplete) {
      navigationUrl = '';      
    } else if (isActive) {
      navigationUrl = `/dashboard/plans/${id}`;
    } else if (inReview){
      navigationUrl = `/dashboard/apply-for-plans?step=6&planId=${id}`;
      step=7;
    } else if (hasPaymentMethod) {
      navigationUrl = `/dashboard/apply-for-plans?step=5&planId=${id}`;
      step=5;
    } else if (hasLease) {
      navigationUrl = `/dashboard/apply-for-plans?step=3&planId=${id}`;
      step=3;
    } else if (hasProperty){
      navigationUrl = `/dashboard/apply-for-plans?step=1&planId=${id}`;
      step=1;
    } else {
      navigationUrl = `/dashboard/apply-for-plans?step=0&planId=${id}`;
      step=0;
    }    

    return {navigationUrl, step};
  };

  const isValidStatus = (status) => !excludedStatuses.includes(status);

  return (
    <table className='flex flex-col w-full'>
      {/* body */}
      <tbody className='w-full rounded-xl border border-gray-200 divide-y'>
        {plans.map(
          (plan, index) => {
            if (isValidStatus(plan.status)) {
              return (<>
                <tr
                  key={index}
                  onClick={() => navigate(getNavURL(plan, plan.id).navigationUrl)}
                  className='grid grid-cols-2 w-full hover:bg-gray-50 px-3 py-2 cursor-pointer justify-center sm:grid-cols-[1fr_auto_140px]'
                >
                  {/* address, start date, end date */}
                  <td className='flex flex-col gap-1 w-full p-3 text-base font-medium items-start col-span-2 sm:col-span-1'>
                    {plan?.lease?.address ? (
                      <>
                        <span className='uppercase tracking-wider font-bold text-sm'>
                          Address
                        </span>
                        <span className='uppercase tracking-wider text-lg'>
                          {plan?.lease?.address?.streetAddress1}
                          {plan?.lease?.address?.streetAddress2 && ', '}
                          {plan?.lease?.address?.streetAddress2},{' '}
                          {plan?.lease?.address?.city},{' '}
                          {plan?.lease?.address?.state},{' '}
                          {plan?.lease?.address?.postalCode}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className='uppercase tracking-wider font-bold text-sm'>
                          Address
                        </span>
                        <span className='uppercase tracking-wider text-lg'>
                          No address
                        </span>
                      </>
                    )}
                  </td>

                  {/* started application */}
                  <td className='flex flex-col gap-1 w-full p-3 text-base font-medium items-start justify-center'>
                    <span className='uppercase tracking-wider font-bold text-sm'>
                      Started On
                    </span>
                    <div className='flex gap-1'>
                      <span className='uppercase tracking-wider text-lg'>
                        {formatDateToHumanDate(plan?.createdAt || '')}
                      </span>
                    </div>
                  </td>

                  {/* status */}
                  <td className='w-full p-3 flex flex-col gap-1 justify-center'>
                    <div className='inline-flex justify-end items-center sm:justify-between'>
                      <StatusLabel
                        status={plan.status}                        
                        // addedText={plan.status === statusEnum.pending && step ? `${(step/7*100).toFixed()}%` : null}
                        size='sm'
                      />

                      {plan?.status === statusEnum.cancelled ? null : (
                        <Icon
                          as={MdKeyboardArrowRight}
                          boxSize={4}
                          className='hidden sm:block'
                        />
                      )}
                    </div>
                  </td>
                </tr>
              </>
              )
            }
          }
        )}
      </tbody>
    </table>
  );
};

export default PlansTable;
