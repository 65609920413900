import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ProgressCard from 'components/shared/progress-card';
import ConfirmActionModal from 'components/shared/confirm-action-modal';
import ApplyForClaimLayout from 'layouts/apply-for-claim';
import ModalLayout from 'layouts/modal';
import {
  clearClaimsDraft,
  selectClaimType,
  selectClaimsStep,
} from 'redux/features/claims/claimsSlice';
import { FaCalendar, FaCheckToSlot, FaClipboardList, FaPaperPlane } from 'react-icons/fa6';
import { FaInfoCircle } from 'react-icons/fa';
import { ApplyAside, ApplyContainer, ApplyHeader, ApplyMain } from 'components/shared/apply-form';

const ApplyForClaim = () => {
  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const draftClaimStep = useSelector(selectClaimsStep);
  const draftClaimType = useSelector(selectClaimType);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsStep = searchParams.get('step');

  // STATES
  const [selectedClaimType, setSelectedClaimType] = useState(1);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [step, setStep] = useState(1);
  const [isCancelModalOpen, setCancelModalState] = useState(false);
  const [isApprovedClaim, setIsApprovedClaim] = useState(false);

  // HANDLERS
  const toggleCancelModal = () => {
    setCancelModalState(!isCancelModalOpen);
  };
  const handleGoToDashboard = () => {
    navigate('/dashboard/claims');
  };

  // SIDE EFFECTS
  useLayoutEffect(() => {
    setSelectedClaimType(draftClaimType);
  }, []);
  useEffect(() => {
    setStep(+paramsStep || draftClaimStep);
  }, []);

  return (
    <>
      <ApplyContainer>

        <ApplyHeader
          onClick={handleGoToDashboard}
        />

        <ApplyMain>

          <ApplyAside
            // Save is always available, but text changes depending on whether the claim has been submitted
            save={handleGoToDashboard}
            saveText={isSubmitted ? 'Go to Home' : 'Save & Continue Later'}
            // Cancel is available only before submission
            cancel={toggleCancelModal}
            cancelCondition={!isSubmitted && !isApprovedClaim && step < 5}
          >             
            <ProgressCard
              CardIcon={FaCalendar}
              title='Cancellation Date'
              step={1}
              isBlurred={step < 1}
              isCompleted={step > 1}
            />
            <ProgressCard
              CardIcon={FaClipboardList}
              title='Claim Type'
              step={2}
              isBlurred={step < 2}
              isCompleted={step > 2}
            />
            <ProgressCard
              CardIcon={FaInfoCircle}
              title='Claim Details'
              step={3}
              isBlurred={step < 3}
              isCompleted={step > 3}
            />
            <ProgressCard
              CardIcon={FaPaperPlane}
              title='Submit Claim'
              isCompleted={step > 4 || isSubmitted}
              isBlurred={step < 4}
              step={4}
            />
            <ProgressCard
              CardIcon={FaCheckToSlot}
              title='Finalize Claim'
              isCompleted={step > 5 || isApprovedClaim}
              step={5}
              isBlurred={step < 5}
            />
          </ApplyAside>

          {/* main section */}
          <ApplyForClaimLayout
            selectedClaimType={selectedClaimType}
            setSelectedClaimType={setSelectedClaimType}
            stepNumber={step}
            setStepNumber={setStep}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            isApprovedClaim={isApprovedClaim}
            setIsApprovedClaim={setIsApprovedClaim}
          />
        </ApplyMain>
      </ApplyContainer>
      
      {/* Modal */}
      <ModalLayout
        toggleModal={toggleCancelModal}
        modalIsOpen={isCancelModalOpen}
      >
        <ConfirmActionModal
          onConfirmClick={() => {
            dispatch(clearClaimsDraft());
            handleGoToDashboard();
          }}
          onCancel={() => setCancelModalState(false)}
        />
      </ModalLayout>
    </>
  );
};

export default ApplyForClaim;
