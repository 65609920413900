import WrapInputLabel from 'components/shared/wrap-input-label';
import { statusEnum } from 'utils/enums';
import ApplyButtons from 'layouts/apply-buttons';
import { useGetAllPlansQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { isEmpty } from 'common/utils/isEmpty';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';
import SelectInput from 'common/components/select-input';
import StatusLabel from 'components/shared/status-label';

const StartClaim = ({
  onNext,
  isValid=true,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  setFieldTouched,
}) => {
  // HOOKS
  const { data: plansData, isLoading: getPlansLoading } = useGetAllPlansQuery();

  // HANDLERS
  const createPlansOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];

    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, status, lease, ...res }) => ({
        label: <div className='w-full flex gap-2 justify-between'>
        <div className='text-base font-semibold'>Plan {id.toUpperCase()}</div>
        <div className='flex gap-2'>
          <div className='text-base font-normal'>
            {lease?.address?.streetAddress1}, {lease?.address?.city}
          </div>
          <StatusLabel status={status} size='sm' />
        </div>        
      </div>,
        value: id,
      })
    );
  };

  return (
    <>
    <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
      <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>
            Cancellation Date
          </h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Select your plan and the date you want to cancel.
          </p>
        </div>

        {/* button */}
      <ApplyButtons
        disableNext={isEmpty(values.planId) || isEmpty(values.moveOutDate)}
        disableBack
        moveOn={true}
        onNextClick={onNext}
        isValid={isValid}
      />
    </div>
    <div className='p-10 overflow-scroll no-scrollbar w-full h-full flex flex-col justify-between gap-8'>
      {/* inputs */}
      <div className='flex flex-col gap-5 w-full'>
        {/* select plan */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired>
            Select Plan:
          </WrapInputLabel>

          <SelectInput
            options={createPlansOptionMenu(plansData)}
            isDisabled={isEmpty(plansData) || getPlansLoading}
            placeholder={
              getPlansLoading
                ? 'Fetching...'
                : isEmpty(plansData)
                ? `No available plan, please create a new one.`
                : 'Type to search for a plan by number'
            }
            name='planId'
            value={createPlansOptionMenu(plansData).find(
              (selectedPlan) => selectedPlan.value === values.planId
            )}
            onChange={(selectedOption) => {
              setFieldValue('planId', selectedOption?.value || '').then(() => {
                setFieldTouched('planId');
              });
            }}
            onBlur={handleBlur}
            isOptionDisabled={(option) => {
              const optionID = option.value;
              const optionFullObject = plansData.find(
                (plan) => plan.id === optionID
              );
              return optionFullObject?.status !== statusEnum.active;
            }}
          />
          <FormError name='planId' />
        </div>

        {/* cancellation date */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired>
            
              Cancellation Date:
            </WrapInputLabel>

          <Input
            placeholderText='Set a cancellation date'
            type='date'
            name='moveOutDate'
            value={values.moveOutDate}
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldTouched('moveOutDate');
              handleChange(e);
            }}
          />
          <FormError name='moveOutDate' />
        </div>
      </div>      
    </div>
    </>
  );
};

export default StartClaim;
