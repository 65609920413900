import Button from 'common/components/button';
import StatusLabel from 'components/shared/status-label';
import DocumentPreview from 'common/components/document-preview';
import { formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { Flex, Text } from '@chakra-ui/react';
import { claimTypeEnum, statusEnum } from 'utils/enums';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import ContactCard from 'components/shared/contact-card';
import ApplyButtons from 'layouts/apply-buttons';

const ClaimSubmittedSuccess = ({ onClick, values }) => {
  // Get document url based on the claim type
  const documentUrl =
    values?.newJob?.offerPdfUrl ||
    values?.purchasedHome?.offerPdfUrl ||
    values?.otherClaim?.supportingDocUrl;

  // Get the document name from the url
  const regex = /https:\/\/cios-api-images\.s3\.amazonaws\.com\/listings\/(.+)/;
  const match = documentUrl?.match(regex);
  const documentName = match ? match[1] : '';

  return (
    <>
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>Claim Submitted</h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Your claim has been successfully submitted for review.
          </p>
        </div>

        {/* button */}
        <ApplyButtons
          moveOn={true}
          isValid={true}
          disableBack
          onNextClick={onClick}
          nextTitle='Track Status'
        />
      </div>
      <div className='p-10 overflow-scroll no-scrollbar w-full h-full flex flex-col gap-8'>
        {/* Contact Card */}
        <ContactCard title='What to Expect After Submitting Your Claim'>
          <Text>
            We are now reviewing your claims based on our terms and notify you
            of the decision through the app. If you have any questions during
            the process, feel free to reach out to our customer service team
            anytime with the information below.
          </Text>
        </ContactCard>

        {/* Submitted Information */}
        <Flex className='w-full flex-col gap-6'>
          {/* header */}
          <Flex className='flex-col gap-4 w-full'>
            <Flex className='gap-4 items-center justify-between'>
              <h2 className='text-2xl font-bold'>Your Submitted Claim</h2>
              <StatusLabel status={values?.status} />
            </Flex>
          </Flex>

          {/* submitted information in card */}
          <InfoCard title='Submitted Information'>
            {/* shared information for all claim types */}
            <InfoGrid>
              <InfoField title='Claim Number'>{values?.id}</InfoField>
              <InfoField title='Submitted On'>
                {formatDateToHumanTime(values?.submissionAt)}
              </InfoField>
              <InfoField title='Cancellation Date'>
                {formatDateToHumanTime(values?.moveOutDate)}
              </InfoField>
              <InfoField title='Claim Type'>
                {values?.claimType === claimTypeEnum.NEW_JOB
                  ? 'New Job'
                  : values?.claimType === claimTypeEnum.PURCHASED_HOME
                  ? 'Purchased Home'
                  : 'Other Claim'}
              </InfoField>
            </InfoGrid>

            {/* information for new jobs */}
            {values?.claimType === claimTypeEnum.NEW_JOB && (
              <InfoGrid>
                <InfoField title='Job Title'>
                  {values?.newJob?.jobTitle}
                </InfoField>
                <InfoField title='Contact Name'>
                  {values?.newJob?.contactName}
                </InfoField>
                <InfoField title='Contact Phone'>
                  {values?.newJob?.contactPhone}
                </InfoField>
                <InfoField title='Contact Email'>
                  {values?.newJob?.contactEmail}
                </InfoField>
                <InfoField title='Offer Date'>
                  {formatDateToHumanTime(values?.newJob?.offerDate)}
                </InfoField>
                <InfoField title='Proposed Start Date'>
                  {formatDateToHumanTime(values?.newJob?.proposedStart)}
                </InfoField>
              </InfoGrid>
            )}

            {/* information for purchased homes */}
            {values?.claimType === claimTypeEnum.PURCHASED_HOME && (
              <InfoGrid>
                <InfoField title='Street Address 1' className='col-span-2'>
                  {values?.purchasedHome?.homeAddress?.streetAddress1}
                </InfoField>
                <InfoField title='Street Address 2' className='col-span-2'>
                  {values?.purchasedHome?.homeAddress?.streetAddress2}
                </InfoField>
                <InfoField title='City'>
                  {values?.purchasedHome?.homeAddress?.city}
                </InfoField>
                <InfoField title='State'>
                  {values?.purchasedHome?.homeAddress?.state}
                </InfoField>
                <InfoField title='Zip Code'>
                  {values?.purchasedHome?.homeAddress?.postalCode}
                </InfoField>
                <InfoField title='Country'>
                  {values?.purchasedHome?.homeAddress?.country}
                </InfoField>
                <InfoField title='Offer Date'>
                  {formatDateToHumanTime(values?.purchasedHome?.offerDate)}
                </InfoField>
                <InfoField title='Expected Possession Date'>
                  {formatDateToHumanTime(values?.purchasedHome?.proposedStart)}
                </InfoField>
              </InfoGrid>
            )}

            {/* information for other claims */}
            {values?.claimType === claimTypeEnum.OTHER_CLAIM && (
              <InfoGrid>
                <InfoField title='Claim Description' className='col-span-4'>
                  {values?.otherClaim?.description}
                </InfoField>
              </InfoGrid>
            )}

            {/* Submitted Documents */}
            <InfoGrid>
              <InfoField title='Submitted Documents' className='col-span-4'>
                <DocumentPreview url={documentUrl} label={documentName} />
              </InfoField>
            </InfoGrid>
          </InfoCard>
        </Flex>
      </div>
    </>
  );
};

export default ClaimSubmittedSuccess;
