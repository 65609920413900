import ApplyButtons from 'layouts/apply-buttons';
import CheckBox from 'components/shared/checkbox';
import { Text } from '@chakra-ui/react';

const StartClaimSelectType = ({
  setSelectedClaimType,
  onNext,
  onBack,
  handleBlur,
  values,
  setFieldValue,
  stepNumber,
}) => {
  return (
    <>
      <div className='flex gap-4 w-full p-10 mobile:px-2 mobile:pt-0 mobile:pb-7 mobile:flex-col'>
        <div className='flex flex-col gap-2 w-full mobile:gap-1'>
          <h1 className='text-3xl mobile:text-2xl font-bold'>Claim Type</h1>
          <p className='font-medium text-xl mobile:text-lg'>
            Tell us the type of claim and why you're moving.
          </p>
        </div>

        {/* button */}
        <ApplyButtons
          moveOn={true}
          onNextClick={onNext}
          onBackClick={onBack}
          isValid={values.claimType}
        />
      </div>
      <div className='p-10 overflow-scroll no-scrollbar w-full h-full flex flex-col justify-between gap-8'>
        {/* inputs */}
        <div className='flex flex-col gap-5 w-full'>
          <Text className='font-medium text-xl'>I'm cancelling my lease:</Text>

          {/* job new employer */}
          <label className='inline-flex items-center gap-12 mobile:gap-3'>
            {/* check */}
            <CheckBox
              name='claimType'
              onBlur={handleBlur}
              onChange={() => {
                setSelectedClaimType(1);
                setFieldValue('claimType', 'newJob');
              }}
              checked={values.claimType === 'newJob'}
            />

            {/* label text */}
            <div className='cursor-pointer rounded-[61px] px-6 py-2 text-lg w-full font-medium text-center gap-3 text-[#191923] bg-slate-100 mobile:text-left mobile:text-base mobile:px-4'>
              To accept a new job or job transfer
            </div>
          </label>

          {/* home purchase plan */}
          <label className='inline-flex items-center gap-12 mobile:gap-3'>
            {/* check */}
            <CheckBox
              name='claimType'
              onBlur={handleBlur}
              onChange={() => {
                setSelectedClaimType(2);
                setFieldValue('claimType', 'homePurchase');
              }}
              checked={values.claimType === 'homePurchase'}
            />

            {/* label text */}
            <div className='cursor-pointer rounded-[61px] px-6 py-2 text-lg w-full font-medium text-center gap-3 text-[#191923] bg-slate-100 mobile:text-left mobile:text-base mobile:px-4'>
              To finalize the purchase of a new home
            </div>
          </label>

          {/* other plans */}
          <label className='inline-flex items-center gap-12 mobile:gap-3'>
            {/* check */}
            <CheckBox
              name='claimType'
              onBlur={handleBlur}
              onChange={() => {
                setSelectedClaimType(3);
                setFieldValue('claimType', 'other');
              }}
              checked={values.claimType === 'other'}
            />

            {/* label text */}
            <div className='cursor-pointer rounded-[61px] px-6 py-2 text-lg w-full font-medium text-center gap-3 text-[#191923] bg-slate-100 mobile:text-left mobile:text-base mobile:px-4'>
              For another reason unrelated to work or home purchase
            </div>
          </label>
        </div>
      </div>
    </>
  );
};

export default StartClaimSelectType;
