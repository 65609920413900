// ENUM VALUES
export const currency = {
  USD: '$',
};
export const planPaymentMode = {
  ONE_TIME: 'onetime',
  SUBSCRIPTION: 'subscription',
};

export const coverageTypes = {
  CANCEL_ANYTIME: 'cancelAnytime',
  LEAVE_ANYTIME: 'leaveAnytime',
};

export const documentStatusEnum = {
  UPLOADED: "uploaded",
  VERIFIED: "verified",
  REJECTED: "rejected",
  NONE: "none",
}

export const statusEnum = {
  // PLANS
  pending: 'pending',
  active: 'active',
  complete: 'complete',
  rejected: 'rejected',
  cancelled: 'cancelled',
  created: 'created',
  review: 'review',
  paid: 'paid',

  // CLAIMS
  Draft: 'Draft',
  submitted: 'submitted',
  Completed: 'Completed',
  inReview: 'in Review',
  Expired: 'Expired',
  Denied: 'Denied',
  Approved: 'Approved',
  Confirmed: 'Confirmed',
};
export const claimTypeEnum = {
  NEW_JOB: 'newJob',
  PURCHASED_HOME: 'purchasedHome',
  OTHER_CLAIM: 'other',
};
export const stripeKYCEnum = {
  pending: 'pending',
  failed: 'error',
  verified: 'verified',
};
export const planPaymentType = {
  ONETIME: 1,
  SUBSCRIPTION: 2,
};
export const DOTS = '...';
export const PENDING = 'pending';
export const PENDING_PAYMENT = 'Pending Payment';
export const ACTIVE = 'Active';

export const MAX_DOCUMENT_SIZE = 20971520; // 20MB
export const MAX_PROFILE_IMAGE_SIZE = 5242880;

// INITIAL VALUES
export const uploadDocumentSchema = {
  path: '',
  name: '',
  size: 0,
  type: '',
  webkitRelativePath: '',
};
export const localeTimeOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const shortTime = new Intl.DateTimeFormat('en', {
  timeStyle: 'short',
});

export const settingsTab = {
  ACCOUNT: 1,
  PAYMENT: 2,
  VERIFICATION: 3,
};

export const iconPositionEnum = {
  LEFT: 1,
  RIGHT: 2,
};

export const POLLING_INTERVAL = 3000; //milliseconds

export const userTrackingEnum = {
  CONSOLE: 'console',
  SEGMENT: 'segment',
};
