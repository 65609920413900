import { FiLogOut } from 'react-icons/fi';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import LogoImage from 'common/components/logo-image';
import Button from 'common/components/button';
import AnchorLinkTag from 'components/shared/anchor-link-tag';

const ApplyContainer = ({ children }) => {
  return (
    <div className='w-full h-[100dvh] flex flex-col px-5 py-6 gap-6 mobile:pb-0'>
      {children}
    </div>
  );
};

const ApplyHeader = ({ onClick }) => {
  return (
    <div className='w-full flex justify-between items-center'>
      <LogoImage className='ml-6 mobile:ml-3' small={true} />

      {/* Buttons */}
      <div className='w-fit flex gap-2'>
        <AnchorLinkTag hrefPath='mailto:members@releaserent.com'>
          <Button theme='secondary' title='Get Help' />
        </AnchorLinkTag>
      </div>
    </div>
  );
};

const ApplyMain = ({ children }) => {
  return (
    <div className='h-full min-w-screen overflow-hidden flex gap-8 mobile:flex-col mobile:gap-5 no-scrollbar'>
      {children}
    </div>
  );
};

const ApplyAside = ({
  children,
  save,
  saveText = 'Save & Continue Later',
  saveCondition = true,
  cancel,
  cancelText = 'Cancel Application',
  cancelCondition = true,
}) => {
  return (
    <aside className='h-full w-full max-w-xs relative flex flex-col gap-20 mobile:max-w-none mobile:px-2 mobile:h-fit'>
      {/* progress */}
      <div className='flex flex-col gap-4 w-full mobile:flex-row mobile:justify-between mobile:bg-slate-200 mobile:gap-0 mobile:rounded-full mobile:overflow-hidden'>
        {children}
      </div>

      {/* save and close application */}
      <div className='flex flex-col w-full px-3 mt-auto mobile:hidden'>
        {/* save and continue later */}
        {saveCondition && save && (
          <p
            onClick={save}
            className='cursor-pointer hover:bg-slate-100 text-base font-medium border-t border-slate-200 px-4 py-4 flex items-center gap-3'
          >
            <FiLogOut className='text-lg text-slate-400' />
            <span>{saveText}</span>
          </p>
        )}

        {/* cancel */}
        {cancelCondition && cancel && (
          <p
            onClick={cancel}
            className='cursor-pointer hover:bg-slate-100 border-y border-slate-200 text-base font-medium py-4 px-4 flex items-center gap-3'
          >
            <HiMiniInformationCircle className='text-xl text-slate-400' />
            <span>{cancelText}</span>
          </p>
        )}
      </div>
    </aside>
  );
};

export { ApplyMain, ApplyAside, ApplyHeader, ApplyContainer };
